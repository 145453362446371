import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useSite } from '../context/SiteContext';
import styles from './Hero.module.css';

const Hero = () => {
  const { slogan, info } = useSite();

  return (
    <section id="aboutus-section" className={styles.hero}>
      <div className={styles.slider}></div>
      <div className={styles.overlay}></div>
      <div className={styles.content}>
        <p className={styles.slogan}>{slogan}</p>
        <div className={styles.aboutUs}>
          <ScrollLink
            to="ps-section"
            smooth={true}
            duration={500}
            offset={-calculateFixedHeaderHeight()}
            className={`${styles.orderButton}`}
          >
            ŞİMDİ SİPARİŞ VER
          </ScrollLink>
          <h2>Pin Up Oyun Dünyası</h2>
          <p className={styles.description}>
            <strong>Pin Up Oyun Dünyası</strong>'na hoş geldiniz, en sevdiğiniz oyunları bulabileceğiniz ve en iyi oyun deneyimini yaşayabileceğiniz yer. Pinupka.perryone.com.ua, hem deneyimli oyuncular hem de yeni başlayanlar için ideal bir oyun mağazasıdır.
          </p>
          <p className={styles.description}>
            Pin Up markamız altında, en popüler oyunları en uygun fiyatlarla sunuyoruz. Kaliteli oyunları keşfetmek ve keyifle vakit geçirmek için doğru yerdesiniz. Modern platformumuz, güvenilir alışveriş ve hızlı teslimat garantisi ile oyun dünyasına adım atmanızı sağlar.
          </p>
          <p className={styles.description}>
            Topluluğumuza katılın ve oyun oynamanın tadını çıkarın. Esnek sipariş seçeneklerimiz ve kullanıcı dostu arayüzümüz sayesinde, Pin Up Oyun Dünyası'nda harika bir oyun macerası sizi bekliyor. Bizi ziyaret edin: <b>pinupka.perryone.com.ua</b> ve oyun keyfine Pin Up tarzında başlayın!
          </p>
        </div>
        <div className={styles.buttons}></div>
      </div>
    </section>
  );
};

const calculateFixedHeaderHeight = () => {
  const headerHeight = 0.13 * window.innerHeight; // 13vh equivalent in pixels
  return headerHeight;
};

export default Hero;
