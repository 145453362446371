import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Return.module.css'; // Stil için bir CSS modülünüz olduğunu varsayıyoruz

const Return = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.returnInfo}>
      <h1>İADE POLİTİKASI</h1>
      <p>{siteName} olarak memnuniyetinizi ön planda tutuyor ve alışveriş deneyiminizi olabildiğince sorunsuz hale getirmeyi amaçlıyoruz. İşte iade işlemini başlatmak için takip etmeniz gereken yönergeler:</p>
      
      <h2>1. İADE ŞARTLARI</h2>
      <p>Ürünler, orijinal ve kullanılmamış durumda, tüm etiketleri ve ambalajlarıyla birlikte geri gönderilmelidir. Özelleştirilmiş ürünler yalnızca kusurlu veya hasarlı geldiklerinde iade edilebilir.</p>
      
      <h2>2. İADE SÜRESİ</h2>
      <p>Teslimat tarihinden itibaren 30 gününüz vardır. İade sürecini başlatmak için müşteri destek ekibimizle hızlıca iletişime geçin.</p>
      
      <h2>3. İADE ADIMLARI</h2>
      <p><strong>Yetki Talebi:</strong> İade yetki kodu talep etmek için müşteri destek ekibimize e-posta gönderin.</p>
      <p><strong>Paketinizi Hazırlayın:</strong> Ürünü, tüm aksesuarlar ve belgelerle birlikte orijinal kutusuna güvenli bir şekilde paketleyin. Sağlanan yetki kodunu ekleyin.</p>
      <p><strong>Ürünü Gönderin:</strong> İade gönderimi için izlenebilir bir posta servisi kullanın. Müşteriler, iade gönderim ücretinden sorumludur; hata tarafımızdan yapılmışsa bu ücret karşılanacaktır.</p>
      
      <h2>4. İADELER VE DEĞİŞİMLER</h2>
      <p>Geri gönderilen ürününüzü aldıktan ve durumunu doğruladıktan sonra, iade veya değişim işlemini başlatacağız. İadeler, orijinal ödeme yöntemine yapılacak ve genellikle 5-7 iş günü içinde yansıyacaktır. Değişimler, ürün mevcudiyetine bağlıdır. Eğer bir ürün mevcut değilse, yerine alternatif bir ürün veya iade sunabiliriz. Orijinal kargo ücretleri iade edilmez, ancak iade hatamızdan kaynaklanıyorsa bu durum farklıdır.</p>
      
      <h2>5. HASARLI VEYA HATALI ÜRÜNLER</h2>
      <p>Hasarlı veya yanlış ürün aldıysanız, müşteri destek ekibimizle hemen iletişime geçin ve fotoğraflar ile detayları paylaşın. Bu tür ürünlerin iade kargo ücretini biz karşılayacağız ve gerekirse iade veya değişim sağlayacağız.</p>
      
      <h2>6. İADE EDİLEMEYEN ÜRÜNLER</h2>
      <p>Hijyen açısından hassas ürünler gibi bazı ürünler, sağlık nedenleriyle iade edilemez. Detaylar için belirli ürün sayfalarına göz atabilir veya netlik için müşteri destek ekibimize ulaşabilirsiniz.</p>
      
      <h2>7. İADE POLİTİKASI İSTİSNALARI</h2>
      <p>Üçüncü taraf satıcılardan veya harici platformlardan satın alınan ürünler {siteName} üzerinden iade edilemez. Satıcının iade politikasını satın alma işlemi yapmadan önce gözden geçirmenizi öneririz.</p>
      
      <p>İade işleminizle ilgili herhangi bir sorunuz veya yardım ihtiyacınız varsa, destek ekibimizle <a href={`mailto:support@${siteName.toLowerCase()}`}>support@{siteName}</a> adresinden iletişime geçmekten çekinmeyin. {siteName} olarak, her alışverişinizde mükemmel hizmet sunma ve memnuniyetinizi sağlama konusunda kararlıyız.</p>
    </div>
  );
};

export default Return;
