import React from 'react';
import ProductCard from '../components/ProductCard';
import products from '../data/productsData';
import styles from './Home.module.css';
import Hero from '../components/Hero';
const Home = () => {
  return (
    <>
      <Hero />
      <div id="products-section" className={styles.container}>
        <h1 id="ps-section" className={styles.heading}>PS için Pin Up Teklifleri</h1>
        <div className={styles.grid}>
          {products.slice(0, 3).map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>

        <h1  id="xbox-section" className={styles.heading}>
        XBox için Teklifleri Sabitleyin</h1>
        <div  className={styles.grid}>
          {products.slice(3, 6).map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Home;