import image1 from '../assets/image1.webp';
import image2 from '../assets/image2.webp';
import image3 from '../assets/image3.webp';
import image4 from '../assets/image4.webp';
import image5 from '../assets/image5.webp';
import image6 from '../assets/image6.webp';

const products = [
  { id: 1, name: 'Cyberpunk 2027', price: '100', image: image1, badge: 'PS', description: 'Gelecekte geçen aksiyon dolu bir açık dünya oyunu.' },
  { id: 2, name: 'Monster Hunter', price: '200', image: image2, badge: 'PS', description: 'Devasa canavarları avladığınız heyecan verici bir macera.' },
  { id: 3, name: 'Dark Souls Remastered', price: '300', image: image3, badge: 'PS', description: 'Zorlu ve sürükleyici bir rol yapma oyunu.' },
  { id: 4, name: 'Peppa Pig', price: '150', image: image4, badge: 'Xbox', description: 'Çocuklar için eğlenceli ve eğitici bir oyun.' },
  { id: 5, name: 'Life is Strange: True Colors', price: '250', image: image5, badge: 'Xbox', description: 'Duygusal bir hikaye sunan bir macera oyunu.' },
  { id: 6, name: 'Immortals Fenyx Rising', price: '350', image: image6, badge: 'Xbox', description: 'Efsanevi yaratıklarla dolu bir açık dünya aksiyon oyunu.' },
];

export default products;
