import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Terms.module.css'; // Stil için bir CSS modülünüz olduğunu varsayıyoruz

const Terms = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.termsInfo}>
      <h1>HİZMET ŞARTLARI</h1>
      <p>{siteName}'a hoş geldiniz! Web sitemizi erişim ve kullanımınızla, aşağıdaki şart ve koşullara uymayı kabul etmiş olursunuz. Kabul etmiyorsanız, lütfen sitemizi kullanmayın.</p>
      
      <h2>1. FIKRI MÜLKİYET HAKLARI</h2>
      <p>Web sitemizdeki tüm içerik, metin, grafikler, logolar, simgeler, görseller ve videolar dahil, {siteName}’a ait olup telif hakkı ve fikri mülkiyet yasalarıyla korunmaktadır. Yetkisiz kullanım veya çoğaltma kesinlikle yasaktır.</p>
      
      <h2>2. KULLANIM KURALLARI</h2>
      <p>Web sitemiz yalnızca kişisel, ticari olmayan kullanım içindir. İçeriği değiştiremez, dağıtamaz veya çoğaltamazsınız, açık yazılı izin olmadan. Sitemizin işlevselliğini veya güvenliğini tehlikeye atabilecek faaliyetler, örneğin zararlı yazılım yerleştirmek yasaktır.</p>
      
      <h2>3. BİLGİ DOĞRULUĞU</h2>
      <p>Tüm ürün bilgilerini doğru ve güncel tutmak için çaba gösteriyoruz. Ancak, tüm ayrıntıların hatasız olduğunu garanti edemeyiz. Ürün açıklamaları, fiyatlar ve mevcudiyetteki hataları düzeltme hakkını saklı tutarız, önceden bildirimde bulunmadan.</p>
      
      <h2>4. FİYATLAR VE İŞLEMLER</h2>
      <p>Sitemizdeki fiyatlar değişebilir ve ilgili para biriminde görüntülenir. Güvenli ödeme seçenekleri sunuyoruz ve kabul edilen ödeme yöntemleri ödeme sırasında belirtilir.</p>
      
      <h2>5. SİPARİŞLER VE İPTALLER</h2>
      <p>Bir sipariş vermek, satın alma teklifinde bulunmak anlamına gelir ve bunu kabul edebilir veya reddedebiliriz. Stok mevcudiyeti veya ödeme sorunları gibi nedenlerle siparişleri iptal etme hakkını saklı tutarız. Etkilenen müşteriler derhal bilgilendirilecek ve geri ödemeler yapılacaktır.</p>
      
      <h2>6. KARGO VE İADE</h2>
      <p>Çeşitli yerlere kargo hizmeti sunuyoruz, ücretler ve teslimat süreleri yöntem ve varış yerine göre değişir. Daha fazla bilgi için detaylı Kargo ve İade Politikamıza başvurun.</p>
      
      <h2>7. DIŞ BAĞLANTILAR</h2>
      <p>Sitemizde bizim tarafımızdan işletilmeyen dış sitelere bağlantılar bulunabilir. Bu dış sitelerin içeriğinden veya gizlilik uygulamalarından sorumlu değiliz, bu nedenle bu sitelere erişirken dikkatli olun.</p>
      
      <h2>8. SORUMLULUK SINIRLAMASI</h2>
      <p>{siteName} veya bağlı kuruluşları, web sitemizin kullanımından veya sağlanan bilgilere güvenilmesinden kaynaklanan herhangi bir doğrudan, dolaylı, tesadüfi, özel veya sonuç olarak ortaya çıkan zararlardan sorumlu değildir.</p>
      
      <h2>9. ŞARTLARIN KABULÜ</h2>
      <p>Bu şartlar önceden bildirimde bulunmadan değiştirilebilir. Sitemizin güncellenen şartlarını kullanmaya devam etmeniz, revize edilmiş şartları kabul ettiğiniz anlamına gelir. Bu şartlarla ilgili herhangi bir sorunuz varsa, bizimle <a href={`mailto:info@${siteName.toLowerCase()}`}>info@{siteName}</a> adresinden iletişime geçebilirsiniz.</p>
    </div>
  );
};

export default Terms;
