import React from 'react';
import styles from './Footer.module.css'; // Stil için CSS modülünü içe aktarın
import SubscriptionForm from './SubscriptionForm'; // SubscriptionForm bileşenini içe aktarın
import { useSite } from '../context/SiteContext';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { siteName } = useSite();
  const currentYear = new Date().getFullYear(); // Geçerli yılı alın

  return (
    <footer className={styles.footer}>
      <div className={styles.linksRow}>
        <Link to="/return" className={styles.footerLink}>İade Politikası</Link>
        <Link to="/delivery" className={styles.footerLink}>Teslimat Politikası</Link>
      </div>
      <div className={styles.linksRow}>
        <Link to="/terms" className={styles.footerLink}>Şartlar ve Koşullar</Link>
        <Link to="/privacy" className={styles.footerLink}>Gizlilik Politikası</Link>
      </div>
      <SubscriptionForm /> {/* SubscriptionForm bileşenini buraya ekleyin */}
   
      <div className={styles.footerCopyright}>
        {siteName} &copy; {currentYear} <br/> Tüm hakları saklıdır.
      </div>
    </footer>
  );
};

export default Footer;
