import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Delivery.module.css'; // Stil için bir CSS modülünüz olduğunu varsayıyoruz

const Delivery = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.shippingInfo}>
      <h1>TESLİMAT BİLGİLERİ</h1>
      <p>{siteName}'ye hoş geldiniz! Siparişlerinizi en hızlı ve güvenli şekilde size ulaştırmak için buradayız. Teslimat politikalarımız hakkında bilmeniz gerekenler:</p>
      
      <h2>1. SİPARİŞ İŞLEME SÜRESİ</h2>
      <p>Siparişinizi verdiğiniz anda işlemeye başlıyoruz. İşleme süresi genellikle 1-3 iş günü sürer ve sipariş yoğunluğuna veya ürün stok durumuna bağlı olarak değişebilir. Süreç boyunca sizi bilgilendireceğiz.</p>
      
      <h2>2. TESLİMAT SÜRELERİ VE YÖNTEMLERİ</h2>
      <p>Teslimat süreleri, bulunduğunuz konuma ve seçtiğiniz gönderim yöntemine göre değişiklik gösterir. Standart teslimat genellikle 5-7 iş günü sürer, ancak daha hızlı teslimat seçenekleri de mevcuttur. Tahmini teslimat süreleri ödeme sırasında belirtilecektir.</p>
      
      <h2>3. TESLİMAT ÜCRETLERİ</h2>
      <p>Teslimat ücretleri, siparişinizin ağırlığına ve bulunduğunuz yere göre hesaplanır. Belirli bir tutarın üzerindeki siparişlerde ücretsiz kargo seçenekleri mevcuttur.</p>
      
      <h2>4. SİPARİŞ TAKİBİ</h2>
      <p>Siparişiniz kargoya verildikten sonra, takip numarasını e-posta ile size göndereceğiz. Ayrıca, sitemizdeki hesabınız üzerinden de sipariş durumunuzu takip edebilirsiniz.</p>
      
      <h2>5. GARANTİ POLİTİKASI</h2>
      <p>Ürünlerimizin kalitesine güveniyoruz. Garanti kapsamında olan herhangi bir kusurla karşılaşırsanız, garanti süresi içinde destek ekibimizle iletişime geçin ve faturanızı ibraz edin. Ürünün garanti kapsamında olup olmadığını değerlendiririz.</p>
      
      <h2>6. GARANTİ TALEPLERİNİN İŞLENMESİ</h2>
      <p>Geçerli garanti taleplerinde, ürünü onaracak veya değiştireceğiz. Orijinal ürün temin edilemiyorsa, eşdeğer veya daha değerli bir ürünle değiştirilecektir. Tüm onarımlar veya değişimler, orijinal satın almanın garanti süresi içinde olacaktır.</p>
      
      <h2>7. GARANTİ KAPSAMI DIŞI DURUMLAR</h2>
      <p>Garanti politikamız şu durumları kapsamaz:</p>
      <ul>
        <li>Normal aşınma ve yıpranma</li>
        <li>Kötü kullanım, kaza veya kasıtlı hasar</li>
        <li>Yetkisiz değişiklikler veya onarımlar</li>
        <li>Bakım talimatlarına uyulmaması</li>
        <li>Kaza veya doğal afetler gibi dışsal olaylar</li>
      </ul>
      
      <h2>8. SİPARİŞ ONAYI</h2>
      <p>Teslimat bilgilerinizin doğru olduğundan emin olun. Gecikmeleri önlemek için ödeme sırasında tüm bilgileri dikkatlice kontrol edin.</p>
      
      <h2>9. İADE SÜRECİ</h2>
      <p>Siparişiniz, hatalı iletişim bilgileri nedeniyle geri gönderilirse veya teslim alma sürecinde bulunamazsanız, yeni bir teslimat düzenlemek için sizinle iletişime geçeceğiz. Bu durumda ek ücretler uygulanabilir.</p>
      
      <h2>10. TESLİMAT KISITLAMALARI</h2>
      <p>Bazı ürünler, doğası veya hedef konumu nedeniyle gönderim kısıtlamalarına tabi olabilir. Herhangi bir kısıtlama durumunda sizi bilgilendireceğiz ve alternatifler sunacağız.</p>
      
      <p>Daha fazla bilgi veya yardım için lütfen <a href={`mailto:info@${siteName.toLowerCase()}`}>info@{siteName}</a> adresinden müşteri desteğimizle iletişime geçin. Siparişinizin doğru ve zamanında ulaşmasını sağlamak için buradayız.</p>
    </div>
  );
};

export default Delivery;
