import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Privacy.module.css'; // Stil için bir CSS modülünüz olduğunu varsayıyoruz

const Privacy = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.privacyInfo}>
      <h1>GİZLİLİK SÖZLEŞMESİ</h1>
      <p>{siteName}'de, gizliliğinizi ve bilgilerinizi korumayı öncelikli olarak kabul ediyoruz. Bu belge, verilerinizi koruma konusundaki taahhüdümüzü ve hizmetlerimizi ve web sitemizi kullanarak kabul ettiğiniz uygulamaları açıklar.</p>

      <h2>1. BİLGİ TOPLAMA</h2>
      <p><strong>Kişisel Veriler:</strong> İşlemler sırasında adınız, e-posta adresiniz, posta adresiniz ve ödeme bilgileriniz gibi gerekli bilgileri topluyoruz.</p>
      <p><strong>Etkileşim Verileri:</strong> Web sitemizle etkileşimlerinizi çerezler gibi teknolojiler kullanarak izliyoruz; IP adresiniz ve tarayıcı türünüzü kaydediyoruz.</p>

      <h2>2. BİLGİLERİN KULLANIMI</h2>
      <p><strong>İşlem Yönetimi:</strong> Kişisel verilerinizi siparişlerinizi yönetmek ve tamamlamak için kullanıyoruz ve işlem sürecinde iletişimi sürdürüyoruz.</p>
      <p><strong>İletişim:</strong> Sipariş onayları ve güncellemeler göndermek için iletişim bilgilerinizi kullanıyoruz.</p>
      <p><strong>Kişiselleştirme:</strong> Onayınız ile, geçmiş etkileşimleriniz ve tercihleriniz doğrultusunda web sitesi deneyiminizi kişiselleştiriyoruz.</p>
      <p><strong>Hizmet Geliştirme:</strong> Topladığımız verileri, web sitemizi ve hizmetlerimizi sürekli olarak iyileştirmek için analiz ediyoruz.</p>

      <h2>3. BİLGİLERİN AÇIKLANMASI</h2>
      <p><strong>İşletme Faaliyetleri:</strong> Verilerinizi, lojistik ve ödeme işlemleri gibi operasyonlarda yardımcı olması için üçüncü taraf hizmet sağlayıcılarıyla sıkı gizlilik anlaşmaları altında paylaşabiliriz.</p>
      <p><strong>Yasal Yükümlülükler:</strong> Yasal olarak gerekliyse, haklarımızı korumak, hizmet güvenliğini sağlamak veya kamu güvenliğini korumak için bilgilerinizi açıklayabiliriz.</p>

      <h2>4. GÜVENLİK ÖNLEMLERİ</h2>
      <p>Güvenlik protokollerimiz, kişisel bilgilerinizi yetkisiz erişim ve kötüye kullanım risklerinden korumak üzere tasarlanmıştır. Ancak, çevrimiçi bilgi iletmenin doğasında bazı riskler bulunduğunu lütfen unutmayın.</p>

      <h2>5. GİZLİLİK SEÇENEKLERİNİZ</h2>
      <p><strong>Pazarlama Reddedilme:</strong> Pazarlama mesajlarını almaktan her zaman vazgeçebilirsiniz. İletişimlerimizdeki bağlantıları kullanarak veya doğrudan bizimle iletişime geçerek bunu yapabilirsiniz.</p>
      <p><strong>Çerezler:</strong> Çerez tercihlerinizi web tarayıcınızın ayarları aracılığıyla yönetebilirsiniz.</p>

      <h2>6. ÇOCUK GÜVENLİĞİ</h2>
      <p>16 yaşından küçük bireylerden veri toplamadığımızı belirtmek isteriz. Yanlışlıkla topladığımız bilgiler, sistemlerimizden hemen silinecektir.</p>

      <h2>7. POLİTİKA GÜNCELLEMELERİ</h2>
      <p>Gizlilik Politikasını düzenli olarak güncelleriz. Politikanın güncellemelerini gözden geçirmenizi teşvik ederiz; güncellemelerden sonra hizmetlerimizi kullanmaya devam etmek, değişiklikleri kabul ettiğiniz anlamına gelir.</p>

      <p>Gizlilik uygulamalarımız hakkında daha fazla bilgi veya yardım için lütfen <a href={`mailto:info@${siteName.toLowerCase()}`}>info@{siteName}</a> adresinden bizimle iletişime geçin. Endişelerinizi hızlı bir şekilde ele almaya ve gizlilik haklarınızı korumaya kararlıyız.</p>
    </div>
  );
};

export default Privacy;
